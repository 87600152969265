import {
  SET_SELLERDATA,
  SET_WORKINFO,
  SET_WORK_PROFILE,
  SET_PROFILES,
  SET_ONGOING_WORK,
  SET_WORK_HISTORY,
  ADD_PROMO_PROFILE,
  SET_REVIEWS,
  SET_WALLET,
  SET_NOTIFICATIONS,
  SET_PROFILE_PREVIEW,
  SET_WORK_INFO_ADMIN,
  SET_QUALIFICATION,
  SET_SERVICE_INFO_ADMIN,
  SET_JOB_TITLES,
} from "./actionTypes";

export const setSellerData = (payload) => {
  return {
    type: SET_SELLERDATA,
    payload,
  };
};

export const setWorkInfo = (payload) => {
  return {
    type: SET_WORKINFO,
    payload,
  };
};

export const setWorkInfoAdmin = (payload) => {
  return {
    type: SET_WORK_INFO_ADMIN,
    payload,
  };
};

export const setQualification = (payload) => {
  return {
    type: SET_QUALIFICATION,
    payload,
  };
};

export const setWorkProfile = (payload) => {
  return {
    type: SET_WORK_PROFILE,
    payload,
  };
};

export const setProfiles = (payload) => {
  return {
    type: SET_PROFILES,
    payload,
  };
};

export const setOngoingWork = (payload) => {
  return {
    type: SET_ONGOING_WORK,
    payload,
  };
};

export const setWorkHistory = (payload) => {
  return {
    type: SET_WORK_HISTORY,
    payload,
  };
};

export const addPromoProfile = (payload) => {
  return {
    type: ADD_PROMO_PROFILE,
    payload,
  };
};

export const setReview = (payload) => {
  return {
    type: SET_REVIEWS,
    payload,
  };
};

export const setWallet = (payload) => {
  return {
    type: SET_WALLET,
    payload,
  };
};

export const setNotifications = (payload) => {
  return {
    type: SET_NOTIFICATIONS,
    payload,
  };
};

export const setProfilePreview = (payload) => {
  return {
    type: SET_PROFILE_PREVIEW,
    payload,
  };
};

export const setServiceInfoAdmin = (payload) => {
  return {
    type: SET_SERVICE_INFO_ADMIN,
    payload,
  };
};

export const setJobTitles = (payload) => {
  return {
    type: SET_JOB_TITLES,
    payload,
  };
};
