import { lazy } from 'react';
import { editProfileRoutes } from './pages/EditProfiles/routes';
import { newProfileRoutes } from './pages/NewProfile/routes';
import { onboardingRoutes } from './pages/Onboarding/routes';

const SellerProfilePreview = lazy(() =>
  import('./pages/SellerProfilePreview/')
);
const Portfolio = lazy(() => import('./pages/Portfolio/Portfolio'));
const CreateGallery = lazy(() => import('./pages/CreateGallery/CreateGallery'));
const WorkProfile = lazy(() => import('./pages/WorkProfile/WorkProfile'));
const EditProfile = lazy(() => import('./pages/EditProfile/EditProfile'));
const ViewGallery = lazy(() => import('./pages/ViewGallery/ViewGallery'));

const SellerWorkManagement = lazy(() =>
  import('./pages/WorkManagement/WorkManagement')
);
const OngoingJobs = lazy(() => import('./pages/WorkManagement/OngoingJobs'));
const WorkHistory = lazy(() => import('./pages/WorkManagement/WorkHistory'));
const SellerReviews = lazy(() => import('./pages/Reviews/SellerReview'));
const PersonalInfo = lazy(() =>
  import('../buyer/pages/PersonalInfo/PersonalInfo')
);
const Wallet = lazy(() => import('./pages/Wallet/Wallet'));

const Education = lazy(() => import('./pages/Education/Education'));
const CreatePromo = lazy(() => import('./pages/Promo/create'));
const PromoProfile = lazy(() => import('./pages/Promo/profile'));
const EditPromoProfile = lazy(() => import('./pages/Promo/edit'));
const SellerHomePage = lazy(() => import('./pages/SellerHomePage/new'));
const InboxPage = lazy(() => import('./pages/Inbox/Inbox'));

const ProfileScore = lazy(() => import('./pages/ProfileScore'));

const routes = [
  {
    path: '/services',
    component: SellerHomePage,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/inbox/1',
    component: InboxPage,
    exact: true,
    auth: true,
    fullScreen: true,
    role: 'Sellers',
  },
  {
    path: '/services/preview-profile/:urlName/:profileId',
    component: SellerProfilePreview,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/portfolio',
    component: Portfolio,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/profile/:id',
    component: WorkProfile,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/gallery/create',
    component: CreateGallery,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/profile/edit/:id',
    component: EditProfile,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/gallery/:id',
    component: ViewGallery,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/work',
    component: SellerWorkManagement,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/work/ongoing',
    component: OngoingJobs,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/work/history',
    component: WorkHistory,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/reviews',
    component: SellerReviews,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/personal-info',
    component: PersonalInfo,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/wallet',
    component: Wallet,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/education',
    component: Education,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/promo-profile/create',
    component: CreatePromo,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/promo-profile/edit/:id',
    component: EditPromoProfile,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/promo-profile/:id',
    component: PromoProfile,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  {
    path: '/services/profile-score/:id',
    component: ProfileScore,
    exact: true,
    auth: true,
    role: 'Sellers',
  },
  ...onboardingRoutes,
  ...newProfileRoutes,
  ...editProfileRoutes,
];

export default routes;
