import React from 'react';
import { levelEmblem, levelText, levelObject } from './level';
import moment from 'moment';
import * as DOMPurify from 'dompurify';

export const replaceSpaces = (string, replacer) => {
  return string
    .trim()
    .split(' ')
    .filter((word) => word.length > 0)
    .join(replacer);
};

export const replaceItem = (string = '', separator, replacer) => {
  return string
    .trim()
    .split(separator)
    .filter((word) => word.length > 0)
    .join(replacer);
};

export const trimText = (text = '', limit) => {
  return text.length > limit ? `${text.substring(0, limit)} ...` : text;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const extractMiniProfile = (profile) => {
  return {
    id: profile.profile_id,
    url: `${profile.seller.url_name}/${profile.profile_id}`,
    display_name: profile.seller.display_name,
    starting_from: profile.starting_from,
    service: profile.service,
    service_photo: profile.service_overview_photo,
    services_overview_title: profile.services_overview_title,
    review_score: profile.seller.reviews.average_overall_score,
    no_of_reviews: Math.floor(profile.seller.reviews.average_overall_score),
    level: levelText(profile.seller.level_tag),
  };
};

export const extractProfile = (profile) => {
  return {
    id: profile.profile_id,
    url: `${profile.url_name}/${profile.profile_id}`,
    display_name: profile.display_name,
    starting_from: profile.starting_from,
    service: profile.service,
    service_photo: profile.service_photo,
    services_overview_title: profile.services_overview_title,
    review_score: profile.review_score,
    no_of_reviews: Math.floor(profile.no_of_reviews),
    level: levelText(profile.level_tag),
    currencyId: profile.currency_id,
  };
};

export const AddDurationUnit = (time) => {
  if (time <= 24) {
    return `${time} hr${time > 1 ? 's' : ''}`;
  }
  const timeInDays = Math.round(time / 24);
  return `${timeInDays} day${timeInDays > 1 ? 's' : ''}`;
};

export const PackageDuration = (time, unit) => {
  const unitString = unit === 2 ? 'day' : 'hour';
  return `${time} ${unitString}${time > 1 ? 's' : ''}`;
};

export const convertTime = (time, unit) => {
  if (unit === 2) {
    return Math.round(time / 24);
  } else {
    return time;
  }
};

export const extractCompareProfile = (profile) => {
  const NONE_SELECTED = 'None Selected';

  return {
    id: profile.profile_id,
    url: `${profile.seller.url_name}/${profile.profile_id}`,
    display_name: profile.seller.display_name,
    starting_from: profile.starting_from,
    service: profile.service,
    service_photo: profile.service_overview_photo,
    services_overview_title: profile.services_overview_title,
    level: (
      <img
        src={levelEmblem(profile.seller.level_tag)}
        alt=""
        className="level"
      />
    ),
    review_score: profile.seller.reviews.average_overall_score,
    no_of_reviews: profile.seller.reviews.review_count,
    location: `${profile.contactInfo.city}, ${
      profile.contactInfo.country === NONE_SELECTED
        ? ''
        : profile.contactInfo.country
    }`,
    registration_type: profile.registrationType.name,
    jobs_completed: profile.statisticInfo.no_of_completed_work,
    notice_period: `${profile.workInfo.notice_period} day${
      profile.workInfo.notice_period > 1 ? 's' : ''
    }`,
    least_job_duration: AddDurationUnit(
      profile.packages[0].express_delivery_time
    ),
    allow_work_cancellation: yesOrNo(profile.workInfo.cancel_work, 1),
    logo: profile.logo_or_profile_pic,
    seller_id: profile.packages[0].seller_id,
    summary: profile.workInfo.summary || 'Nil',
  };
};

export const extractCompareHeader = (profile) => {
  return {
    id: profile.id,
    url: profile.url,
    display_name: profile.display_name,
    starting_from: profile.starting_from,
    service_photo: profile.service_photo,
    services_overview_title: profile.services_overview_title,
    logo: profile.logo,
    seller_id: profile.seller_id,
  };
};

export const fragmentize = (elem) => {
  return <>{elem}</>;
};

export const WorkCancellation = (value) => {
  return `Work Cancellation is ${!value ? 'Not' : ''} Allowed`;
};

export const extractMicroProfile = (profile) => {
  return {
    id: profile.profile_id,
    name: profile.display_name || profile.seller.display_name,
    urlName: profile.url_name || profile.seller.url_name,
    image: profile.service_overview_photo || profile.service_photo,
    work: profile.services_overview_title,
    price: profile.starting_from,
  };
};

export const dayConverter = (num) => {
  if (num < 24) {
    return `${num} hrs`;
  }
  const numberInDays = Math.ceil(num / 24);
  return `${numberInDays} day${numberInDays > 1 ? 's' : ''}`;
};

export const noticePeriod = (num) => {
  return `${num} day${num.length > 1 ? 's' : ''}`;
};

export const dayOrDays = (num) => {
  return `${num} day${parseInt(num, 10) > 1 ? 's' : ''}`;
};

export const workCancellationBool = (bool) => {
  return bool ? 'Yes' : 'No';
};

export const yesOrNo = (bool, yesValue) => {
  return bool === yesValue ? 'Yes' : 'No';
};

export const setNegativeToZero = (value) => {
  return Number(value) < 0 ? 0 : value;
};

export const extractSellerDashboard = (seller) => {
  return {
    id: seller?.id,
    username: seller?.username,
    avatar: seller?.avatar,
    type: seller?.registrationType.name,
    language: seller?.languages.join(', '),
    location: `${
      seller?.contactInfo?.city !== 'None Selected'
        ? seller?.contactInfo?.city
        : ''
    }, ${
      seller?.contactInfo?.country !== 'None Selected'
        ? seller?.contactInfo?.country
        : ''
    }`,
    level: levelObject(seller?.level_tag),
    score: seller?.review_stat?.average_overall_score,
    count: seller?.review_stat?.review_count,
    onlineStatus: seller?.onlineStatus?.online_status,
    summary: seller?.workInfo?.summary,
    stats: [
      {
        title: 'Total No Work Completed:',
        value: setNegativeToZero(seller?.statisticInfo?.no_of_completed_work),
      },
      {
        title: 'Work Successfully Completed:',
        value: setNegativeToZero(
          seller?.statisticInfo?.no_of_work_completed_successfully
        ),
      },
      {
        title: 'Work Unsuccessfully Completed:',
        value: setNegativeToZero(
          seller?.statisticInfo?.no_of_work_completed_unsuccessfully
        ),
      },
      {
        title: 'Jobs in Queue',
        value: setNegativeToZero(seller?.statisticInfo?.no_of_ongoing_work),
      },
      {
        title: 'Notice Period (In Days):',
        value: noticePeriod(seller?.workInfo?.notice_period),
      },
      {
        title: 'Allow Work Cancellation:',
        value: workCancellationBool(seller?.workInfo?.cancel_work),
      },
    ],
  };
};

export const removeTimeFromDate = (string) => {
  const date = moment(string).format('ll');
  return date;
};

export const registrationType = (id) => {
  const typeString = ['Individual', 'Corporate'];
  return typeString[id - 1];
};

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export const chatDate = (time) => {
  if (!time) {
    return '';
  }

  const today = moment();
  const chatTime = moment(time);
  const dayDifference = today.diff(chatTime, 'days');

  if (dayDifference < 1) {
    return chatTime.format('HH:mm');
  } else if (dayDifference === 1) {
    return 'Yesterday';
  } else if (dayDifference < 7) {
    return chatTime.format('dddd');
  }
  return chatTime.format('MMM DD');
};

export const groupArrayObj = (arr = [], grouper) => {
  const obj = {};

  for (let i = 0; i < arr.length; i++) {
    const data = arr[i][grouper];
    obj[data] = arr[i];
  }

  return obj;
};

export const groupArr = (arr = [], grouper = '') => {
  const obj = {};

  for (let i = 0; i < arr.length; i++) {
    const objKey = arr[i][grouper];
    if (obj[objKey]) {
      obj[objKey] = [...obj[objKey], arr[i]];
    } else {
      obj[objKey] = [arr[i]];
    }
  }

  return obj;
};

export const groupArrayOfObjects = (list, key) => {
  return list.length > 0
    ? list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {})
    : {};
};

export const sortArrayOfObjAlphabetically = (list = [], key) => {
  return list.sort((a, b) => {
    const textA = a[key].toUpperCase();
    const textB = b[key].toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });
};

export const displayTruncatedName = (name = '') => {
  const TEXT_LIMIT = 15;

  return name.length > TEXT_LIMIT ? `${name.substr(0, TEXT_LIMIT)}...` : name;
};

export const truncateText = (text = '', limit = 1) => {
  return text.length > limit ? `${text.substring(0, limit)}...` : text;
};

export const generatePluralText = (value, baseText) => {
  return Number(value) > 1 ? `${baseText}s` : baseText;
};

export const removeHTMLTags = (text = '') => {
  let dom = new DOMParser().parseFromString(text, 'text/html');
  let finalText = dom.body.textContent.replace(/(\r\n|\n|\r)/gm, '');
  return DOMPurify.sanitize(finalText);
};

export const convertToShortPriceUnit = (price) => {
  if (price >= 1000000000) {
    return `${price / 1000000000}B`;
  } else if (price >= 1000000) {
    return `${price / 1000000}M`;
  } else if (price >= 1000) {
    return `${price / 1000}K`;
  } else {
    return price;
  }
};
