import { faSafari } from '@fortawesome/free-brands-svg-icons';
import {
  faPenFancy,
  faPercent,
  faPercentage,
  faSearchDollar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { ElementColor, TextColor } from '../../../../../constants/Colors';
import {
  BUYER_APPLICATION,
  SELLER_APPLICATION,
} from '../../../../../constants/constants';
import { Bold, GreyText } from '../../../../../styling/Text';
import DropDown from '../../../../library/dropdown';
import { Logo } from '../mobile/styles';
import {
  HStack,
  Box,
  Link as ChakraLink,
  LinkBox,
  Square,
  VStack,
} from '@chakra-ui/layout';
import HeaderSearch from './search';
import {
  LeftLink,
  LeftLinkContainer,
  LeftLinksWrapper,
  LeftStyle,
  LinkIcon,
} from './style';
import Home7LineIcon from 'remixicon-react/Home7LineIcon';
import Building2LineIcon from 'remixicon-react/Building2LineIcon';
import CustomerService2LineIcon from 'remixicon-react/CustomerService2LineIcon';
import Gift2LineIcon from 'remixicon-react/Gift2LineIcon';
import { Icon } from '@chakra-ui/react';
import useAuthentication from '../../../../../hooks/useAuthetication';
import { appEnv } from '../../../../../utils/appEnv';
import Auth from '../../../../../utils/Auth';

export const linksList = {
  talent: [
    {
      title: 'Post a Freelance Job',
      description: 'Get bids for your project',
      url: '/workstore/create',
      icon: faPenFancy,
      iconColor: TextColor.green,
    },
    {
      title: 'Browse Freelancers',
      description: 'Find freelancers in categories',
      url: '/service-categories',
      icon: faSafari,
      iconColor: ElementColor.secondary,
    },
    {
      title: 'Promo',
      description: 'Get freelance services at discounted rate',
      url: '/promo',
      icon: faPercent,
      iconColor: ElementColor.dark,
    },
  ],
  jobs: [
    {
      title: 'Find listed freelance gigs',
      description: 'Find gigs for your skills',
      url: '/freelance-jobs',
      icon: faSearchDollar,
      iconColor: ElementColor.secondary,
    },
    {
      title: 'Unlock opportunities for sale',
      description: 'Get paid for tasks or projects that match your skill sets ',
      url: '/services/portfolio',
      icon: faPercentage,
      iconColor: ElementColor.dark,
    },
  ],
  enterprise: [
    {
      title: 'Home',
      url: 'https://enterprise.terawork.com',
      isExternal: true,
      icon: Home7LineIcon,
      iconColor: ElementColor.secondary,
    },
    {
      title: 'Full-time',
      url: 'https://enterprise.terawork.com/full-time',
      isExternal: true,
      icon: Building2LineIcon,
      iconColor: TextColor.green,
    },
    {
      title: 'Outsourcing',
      url: 'https://enterprise.terawork.com/outsourcing',
      isExternal: true,
      icon: CustomerService2LineIcon,
      iconColor: ElementColor.danger,
    },
    {
      title: 'Hiring Voucher',
      url: 'https://enterprise.terawork.com/credits',
      isExternal: true,
      icon: Gift2LineIcon,
      iconColor: ElementColor.dark,
    },
  ],
};

export const LinkList = ({ links }) => {
  const { authenticated, buyer } = useAuthentication();

  const onClick = (e, url) => {
    e.preventDefault();

    if (authenticated && !!buyer.is_premium) {
      const auth = new Auth();
      const idToken = auth.getUserToken('idToken');

      const urlPath = new URL(url).pathname;
      window.location.href = `${appEnv.enterpriseOAuthUrl}?return_url=${urlPath}&token=${idToken}`;
      return;
    }

    window.location.href = url;
  };

  return (
    <Box py="5" px="0">
      <VStack align="stretch" bg="white" spacing="6" p="8" py="6" shadow="xl">
        {links.map(({ title, url, isExternal, icon, iconColor }) => {
          return (
            <LinkBox key={title} isExternal={isExternal} href={url}>
              <HStack spacing="4">
                <Square
                  bg={`${iconColor}20`}
                  color={iconColor}
                  size="40px"
                  rounded="md"
                >
                  <Icon as={icon} boxSize="1.5rem" />
                </Square>
                {isExternal ? (
                  <ChakraLink
                    href={url}
                    isExternal={isExternal}
                    onClick={(e) => onClick(e, url)}
                    textDecor="none"
                  >
                    {title}
                  </ChakraLink>
                ) : (
                  <ChakraLink as={Link} to={url}>
                    {title}
                  </ChakraLink>
                )}
              </HStack>
            </LinkBox>
          );
        })}
      </VStack>
    </Box>
  );
};

export const LinksMenu = ({ links = [], columns }) => {
  return (
    <LeftLinkContainer>
      <LeftLinksWrapper $columns={columns}>
        {links.map((link) => (
          <LeftLink
            as={link.isExternal ? ChakraLink : LeftLink}
            key={link.url}
            {...(link.isExternal
              ? {
                  href: link.url,
                  _hover: {
                    textDecoration: 'none',
                  },
                }
              : { to: link.url })}
            isExternal={link.isExternal}
          >
            <HStack alignItems="flex-start" spacing="1rem">
              <LinkIcon color={link.iconColor}>
                <FontAwesomeIcon
                  color={link.iconColor}
                  icon={link.icon}
                  size="lg"
                />
              </LinkIcon>
              <VStack align="stretch">
                <Bold as="p">{link.title}</Bold>
                <GreyText>{link.description}</GreyText>
              </VStack>
            </HStack>
          </LeftLink>
        ))}
      </LeftLinksWrapper>
    </LeftLinkContainer>
  );
};

function HeaderLeft(props) {
  const { application, buyer } = props;
  const buyerHasSellerAccount = !!buyer?.seller_id;

  return (
    <LeftStyle>
      <HStack spacing="2rem">
        <Link to={application === BUYER_APPLICATION ? '/' : '/services'}>
          <Logo
            src="https://www.terawork.com/images/logo_black.svg"
            alt="terawork logo"
          />
        </Link>
        <HStack spacing="1rem" fontWeight="500">
          {application !== SELLER_APPLICATION && (
            <DropDown title="Find Freelancers" triggerType="hover">
              <LinksMenu links={linksList.talent} columns="1fr" />
            </DropDown>
          )}
          {
            <DropDown title="Find Freelance Jobs" triggerType="hover">
              <LinksMenu links={linksList.jobs} columns="1fr" />
            </DropDown>
          }

          {/* {application !== SELLER_APPLICATION && (
            <HStack>
              <DropDown title="Enterprise" triggerType="hover" isNew>
                <LinkList links={linksList.enterprise} />
              </DropDown>
            </HStack>
          )} */}

          <HeaderSearch
            {...props}
            buyerHasSellerAccount={buyerHasSellerAccount}
          />
        </HStack>
      </HStack>
    </LeftStyle>
  );
}

export default HeaderLeft;
